import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { CaseCell } from '../SmallCaseCell';
import { ICaseQueryEdge } from '../../../../queries/cases/types';
import { Slider } from '../../../common/Slider';
import useScreen from '../../../../utils/hooks/useScreen';
import { BigCaseCell } from '../BigCaseCell';

interface IProps {
  from?: string;
  cases: ICaseQueryEdge[];
  className?: string;
}

const CaseSmallGrid: FC<IProps> = ({ from, cases }) => {
  const { isMobile } = useScreen();

  return isMobile ? (
    <div className={styles.wrap}>
      <Slider
        sliderProps={{
          items: 1,
          mouseDrag: true,
          slideBy: 'page',
          gutter: 20,
          controls: true,
          nav: false,
          center: false,
          lazyload: false,
          swipeAngle: 45,
          loop: false,
        }}
        className={styles.slider}
        color="#0062FF"
      >
        {cases.map((item) => (
          <div className={styles.item} key={item.node.id}>
            <CaseCell
              item={item.node}
              appearance="round"
              from={from}
              className={styles.cell}
              deviceClassName={styles.cell__device}
            />
          </div>
        ))}
      </Slider>
    </div>
  ) : (
    <div className={classNames(styles.grid)}>
      {cases.map((item, i) =>
        i === 0 ? (
          <BigCaseCell from="/cases" key={item.node.id} item={item.node} />
        ) : (
          <CaseCell item={item.node} from={from} key={item.node.id} />
        )
      )}
    </div>
  );
};

export { CaseSmallGrid };
