import { useMemo } from 'react';
import { IPortfolioTagsQuery } from '../../queries/portfolioTags/types';

export const useDirectionGrouppedCounts = (data: IPortfolioTagsQuery) =>
  useMemo<Record<string, number>>(
    () =>
      data.portfolioTags.group.reduce(
        (obj, group) => ({
          ...obj,
          [`/directions/tags/${group.fieldValue}/`]: group.totalCount,
        }),
        {}
      ),
    [data.portfolioTags]
  );
