import React, { FC, useMemo } from 'react';
import { graphql, PageProps } from 'gatsby';
import { MainLayout } from '../templates/MainLayout';
import styles from './about/styles.module.scss';
import { t } from '../i18n';
import { YellowHeader } from '../components/main/YellowHeader';
import { IHeaderQuery } from '../queries/headers/types';
import { ICaseQuery } from '../queries/cases/types';
import { CaseSmallGrid } from '../components/case/common/CaseSmallGrid';
import { AboutTopDirections } from '../components/about/AboutTopDirections';
import {
  IDirectionTagsQuery,
  IDirectionTagsQueryNode,
  IExpertiseQuery,
} from '../queries/directionTags/types';
import { IPortfolioTagsQuery } from '../queries/portfolioTags/types';
import { useDirectionGrouppedCounts } from '../utils/hooks/queryHooks';
import { IReviewsQuery } from '../queries/reviews/types';
import useScreen from '../utils/hooks/useScreen';
import { AboutReviews } from '../components/about/AboutReviews';
import { IRatingsQuery } from '../queries/ratings/types';
import { IAwardsQuery } from '../queries/awards/types';
import { AboutExpertise } from '../components/about/AboutExpertise';
import { TLink } from '../i18n/TLink';
import { BuyButton } from '../components/main/BuyButton';

type IProps = PageProps<
  IHeaderQuery &
    ICaseQuery &
    IAwardsQuery &
    IRatingsQuery &
    IExpertiseQuery &
    IDirectionTagsQuery &
    IPortfolioTagsQuery &
    IReviewsQuery,
  { locale: string }
>;

const pressData = [
  {
    href: 'https://www.behance.net/IceRock',
    img: '/press/behance.svg',
  },
  {
    href: 'https://kotlinlang.org/lp/mobile/case-studies/icerock/',
    img: '/press/kotlin.png',
  },
  {
    href: 'https://icerock.medium.com/',
    img: '/press/medium.svg',
  },
];

const AboutPage: FC<IProps> = ({ data, pageContext: { locale = 'en' } }) => {
  const header = useMemo(() => data.headers.edges[0], [data.headers]);

  const counters = useMemo(
    () => header && header.node.frontmatter.counters,
    [header]
  );

  const directions: IDirectionTagsQueryNode[] =
    data?.directionTags?.edges || [];

  const counts = useDirectionGrouppedCounts(data);

  const expertises: IDirectionTagsQueryNode[] = data?.expertises?.edges || [];

  const { isMobile } = useScreen();

  const cases = data.projects.edges;

  const reviews = data.reviews.edges;

  return (
    <MainLayout locale={locale}>
      <YellowHeader header={t('about.title')} withLogo>
        {header?.node?.html || ''}
      </YellowHeader>
      <div className={styles.counter}>
        {counters &&
          counters.length > 0 &&
          counters.map(({ items, title }) => (
            <div className={styles.counter__card} key={title}>
              <div className={styles.counter__number}>{items}</div>
              <span>{title}</span>
            </div>
          ))}
      </div>
      {cases && cases.length > 0 && (
        <>
          <div className={styles.title}>
            {isMobile ? t('about.projects') : t('about.top_projects')}
          </div>
          <CaseSmallGrid from="/cases" cases={cases} />
          <TLink to="/cases" className={styles.link}>
            {t('about.link')}
          </TLink>
        </>
      )}

      {directions && directions.length > 0 && (
        <>
          <div className={styles.title}>
            {isMobile ? t('about.directions') : t('about.top_directions')}
          </div>
          <AboutTopDirections
            nodes={directions}
            counts={counts}
            from="/directions"
          />
        </>
      )}

      {expertises && expertises.length > 0 && (
        <>
          <div className={styles.title}>{t('about.expertise.title')}</div>
          {!isMobile && (
            <div className={styles.description}>
              <p>
                {t('about.expertise.description_1.text')}{' '}
                <a
                  target="_blank"
                  href="https://kotlinlang.org/lp/mobile/"
                  rel="noreferrer"
                >
                  {t('about.expertise.description_1.link_text')}
                </a>
              </p>
              <p>
                <a
                  target="_blank"
                  href="https://kotlinlang.org/lp/mobile/"
                  rel="noreferrer"
                >
                  {t('about.expertise.description_2.link_text')}
                </a>{' '}
                {t('about.expertise.description_2.text')}{' '}
              </p>
            </div>
          )}
          <AboutExpertise nodes={expertises} />
        </>
      )}

      <div className={styles.title}>{t('about.we_in_media')}</div>

      <div className="content">
        <div className={styles.media}>
          {pressData.map(({ href, img }) => (
            <a
              href={href}
              className={styles.media__item}
              key={href}
              target="_blank"
              rel="noreferrer"
            >
              <img src={img} alt={href} />
            </a>
          ))}
        </div>
      </div>

      {reviews && reviews.length > 0 && (
        <>
          <div className={styles.title}>{t('about.reviews')}</div>
          <AboutReviews locale={locale} reviews={reviews} />
        </>
      )}

      {isMobile && (
        <div className={styles.order_app__mobile__wrapper}>
          <BuyButton squared>{t('menu.order_app')}</BuyButton>
        </div>
      )}
    </MainLayout>
  );
};

export const query = graphql`
  query {
    headers: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/about/header/" } }
    ) {
      edges {
        node {
          html
          frontmatter {
            counters {
              items
              title
            }
          }
        }
      }
    }

    projects: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/cases/projects/" }
        frontmatter: { promoted: { eq: true } }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      ...CaseFields
    }

    directionTags: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/directions/tags/" }
        frontmatter: { isMain: { eq: true } }
      }
      sort: { fields: frontmatter___id, order: DESC }
    ) {
      ...DirectionTagsFields
    }

    expertises: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/about/expertise/" } }
      sort: { fields: fileAbsolutePath, order: ASC }
    ) {
      ...DirectionTagsFields
    }

    portfolioTags: allMarkdownRemark(
      limit: 2000
      filter: { fileAbsolutePath: { regex: "/cases/project/" } }
    ) {
      group(field: frontmatter___tags) {
        ...PortfolioTagsFields
      }
    }

    awards: allMarkdownRemark(
      limit: 4
      filter: { fileAbsolutePath: { regex: "/about/awards/" } }
    ) {
      ...AwardsFields
    }

    ratings: allMarkdownRemark(
      limit: 4
      filter: { fileAbsolutePath: { regex: "/about/ratings/" } }
      sort: { fields: frontmatter___year, order: DESC }
    ) {
      ...RatingsFields
    }

    reviews: allMarkdownRemark(
      limit: 6
      filter: {
        fileAbsolutePath: { regex: "/reviews/reviews/" }
        frontmatter: { isMain: { eq: true } }
      }
    ) {
      ...ReviewFields
    }
  }
`;

export default AboutPage;
