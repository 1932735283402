import classNames from 'classnames';
import React, { FC } from 'react';
import useScreen from '../../../utils/hooks/useScreen';
import styles from './styles.module.scss';
import { Slider } from '../Slider';

const GridWithSlider: FC = ({ children }) => {
  const { isMobile } = useScreen();

  return isMobile ? (
    <div className={styles.wrap}>
      <Slider
        sliderProps={{
          items: 1,
          mouseDrag: true,
          slideBy: 'page',
          gutter: 20,
          controls: true,
          nav: false,
          center: false,
          lazyload: false,
          swipeAngle: 45,
          loop: false,
        }}
        className={styles.slider}
        color="#0062FF"
      >
        {children}
      </Slider>
    </div>
  ) : (
    <div className={classNames('content', styles.grid)}>{children}</div>
  );
};

export { GridWithSlider };
