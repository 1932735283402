/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import styles from './styles.module.scss';
import { ReviewCard } from '../../reviews/ReviewCard';
import { Slider } from '../../common/Slider';
import { IReviewsQuery } from '../../../queries/reviews/types';

type IProps = {
  reviews: IReviewsQuery['reviews']['edges'];
  locale: string;
};

const AboutReviews: FC<IProps> = ({ reviews, locale }) => (
  <div className={styles.wrap}>
    <Slider
      sliderProps={{
        items: 1,
        mouseDrag: true,
        slideBy: 'page',
        gutter: 20,
        lazyload: false,
        swipeAngle: 45,
        loop: false,
        controls: true,
        autoHeight: true,
        responsive: {
          0: { controls: true, nav: false },
          769: { controls: false, nav: true },
        },
      }}
      containerClassName={styles.sliderContainer}
      className={styles.slider}
      color="#0062FF"
    >
      {reviews.map(({ node }, i) => (
        <div key={i} className={styles.item}>
          <ReviewCard locale={locale} node={node} key={i} />
        </div>
      ))}
    </Slider>
  </div>
);

export { AboutReviews };
