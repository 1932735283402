import React, { FC } from 'react';
import { IDirectionTagsQueryNode } from '../../../queries/directionTags/types';
import { Card } from '../../common/Card';
import { GridWithSlider } from '../../common/GridWithSlider';
import useScreen from '../../../utils/hooks/useScreen';

interface IProps {
  nodes: IDirectionTagsQueryNode[];
  counts: Record<string, number>;
  from?: string;
}

const AboutTopDirections: FC<IProps> = ({ nodes, counts, from }) => {
  const { isMobile } = useScreen();
  const topDirections = [0, 1];

  return (
    <>
      <GridWithSlider>
        {topDirections.map((i) => (
          <Card
            node={nodes[i].node}
            key={`topDirection-${i + 1}`}
            from={from}
            headDirection={isMobile ? 'row-reverse' : 'row'}
          />
        ))}
      </GridWithSlider>
    </>
  );
};

export { AboutTopDirections };
